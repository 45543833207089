<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="index">
    <header>
      <b-navbar class="header-navbar" toggleable="lg" type="dark" variant="transparent">
        <b-navbar-brand href="#">
          <img class="navbar-logo" src="../../assets/images/big_logo.png"/>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
              <b-nav-item href="#">企业入驻</b-nav-item>
              <b-nav-item href="#">商家合作</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header>
    <div class="index-bg">
      <img class="bg" src="../../assets/images/1920.jpg" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "introduction",
    data() {
      return {

      }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
  }
</script>

<style scoped lang="less">
  .index {
    height: 100%;

    .index-bg {
      margin-bottom: 2px;
      text-align: center;
      overflow: hidden;
      height: 100%;

      .bg {
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
    }
  }



  .header-navbar {
    padding: 0 10%;
  }

  .navbar-logo {
    width: 10rem;
    height: 3rem;
  }
</style>
